import React, { Component } from 'react'
import { Form, Container, Header, Card, Modal, Loader, Confirm } from 'semantic-ui-react'
import './App.css'
import 'semantic-ui-css/semantic.min.css'

const kFormFields = Object.freeze({
  name: 'Nume și Prenume',
  phone: 'Număr de telefon',
  age: 'Vârstă'
})

const isNull = o => o === undefined || o === null

class App extends Component {

  constructor(props) {
    super(props)
    this.state = { form: { ...kFormFields }, loading: false, fieldError: {}, submitResponse: null }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange = (_, { name, value }) => {
    const form = this.state.form
    form[name] = value
    this.setState({ form })
  }

  handleSubmit = () => {
    const { phone, fieldError } = this.state.form
    if (phone === '' || isNull(phone) || phone === kFormFields.phone) {
      this.setState({ loading: false, fieldError: { ...fieldError, ...{ phone: 'Numărul de telefon este obligatoriu.' } } })
    } else {
      const req = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(Object.keys(this.state.form).reduce((acc, e) => {
          const formVal = this.state.form[e]
          const defaultVal = kFormFields[e]
          const val = formVal === defaultVal ? '' : formVal
          acc[defaultVal] = val
          return acc
        }, {}))
      }
      const errorState = { loading: false, submitResponse: { ok: false, message: 'S-a produs o eroare. Încercați din nou.' } }
      fetch('https://us-central1-promotii-iulie-august-v1.cloudfunctions.net/server/api/new', req).then(async (res) => {
        if (res.ok && res.status === 200) {
          const { redirect_url } = await res.json()
          this.setState({ loading: false, submitResponse: { ok: true, message: 'Succes!', redirect_url } }, () => {
            this.redirect(redirect_url)
          })
        } else {
          this.setState(errorState)
        }
      }).catch(_ => this.setState(errorState))
    }
  }

  renderFormFields = () => Object.keys(this.state.form).map(key => {
    const value = this.state.form[key]
    const defVal = kFormFields[key]
    const hasError = this.state.fieldError[key]
    return (
      <Form.Input
        key={key}
        fluid
        type={key === 'name' ? 'text' : 'number'}
        disabled={this.state.loading}
        error={isNull(hasError) ? null : hasError}
        label={defVal}
        labelPosition={'left corner'}
        size={'large'}
        placeholder={defVal}
        name={key}
        value={value === defVal ? '' : value}
        onChange={this.handleChange}
      />
    )
  })

  redirect(redirect_url) {
    window.location.href = redirect_url
  }

  handleConfirm = () => {
    const { submitResponse: { ok, redirect_url } } = this.state
    if (ok === true) {
      this.redirect(redirect_url)
    } else {
      this.setState({ loading: false, submitResponse: null })
    }
  }

  render() {
    const { submitResponse } = this.state
    return (
      <Container>
        <Modal open={this.state.loading}>
          <Loader active size='massive'>Se încarcă...</Loader>
        </Modal>
        <Confirm open={!isNull(submitResponse) && !isNull(submitResponse.ok) && submitResponse.ok === false} cancelButton={null} content={submitResponse && submitResponse.message} size='tiny' onConfirm={this.handleConfirm} />
        <Card style={{ backgroundColor: 'whitesmoke', WebkitBoxShadow: '0px -3px 17px -7px rgba(0,0,0,0.44)', MozBoxShadow: '0px -3px 17px -7px rgba(0,0,0,0.44)', boxShadow: '0px - 3px 17px - 7px rgba(0, 0, 0, 0.44)' }}>
          <Card.Content>
            <Header as='h3'>Cupon</Header>
            <Form>
              {this.renderFormFields()}
              <Form.Button disabled={this.state.loading} onClick={() => this.setState({ loading: true }, this.handleSubmit)} content='Înscrie-te' />
            </Form>
          </Card.Content>
        </Card >
      </Container>
    )
  }
}

export default App
